body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: #f8f9fa;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.container {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.content {
  /* background: white; */
  padding: 40px;
  border-radius: 15px;
  /* box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1); */
}

.logo {
  width: 100px;
  height: auto;
  margin-bottom: 20px;
}

.title {
  color: #333;
  font-size: 2.5rem;
  margin: 20px 0 10px;
}

.tagline {
  color: #555;
  font-size: 1.2rem;
  margin: 0;
  max-width: 400px;
  margin: 0 auto;
}
